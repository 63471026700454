import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseHttp, APIUrls } from 'src/app/shared/api';
import { CurrentUser, RequestResponse } from 'src/app/shared/interfaces';

@Injectable()
export class CurrentUserService extends BaseHttp {
  getCurrentUser(): Observable<RequestResponse<CurrentUser>> {
    const url = `${this.baseUrl}/${APIUrls.TMS}/User/current`;
    return this.http.get<RequestResponse<CurrentUser>>(url);
  }
}
