import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable } from 'rxjs';

import { BaseHttp, APIUrls } from 'src/app/shared/api';
import { RequestResponse, Select } from 'src/app/shared/interfaces';
import { ListModel } from 'src/app/shared/models';
import {
  CreateDropdown,
  DropdownFilterData,
  DropdownListItem,
  DropdownResponseData,
} from 'src/app/private/interfaces';

@Injectable()
export class ConfigurationDropdownService extends BaseHttp {
  path: string = 'Dropdown';

  getList(
    filterData: DropdownFilterData
  ): Observable<RequestResponse<ListModel<DropdownListItem>>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/Dashboard`;
    return this.http.post<RequestResponse<ListModel<DropdownListItem>>>(
      url,
      filterData
    );
  }

  removeDropdown(id: string): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/${id}`;
    return this.http.delete<RequestResponse<boolean>>(url);
  }

  getData(id: string): Observable<RequestResponse<DropdownResponseData>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/${id}`;
    return this.http.get<RequestResponse<DropdownResponseData>>(url);
  }

  create(data: CreateDropdown): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}`;
    return this.http.post<RequestResponse<string>>(url, data);
  }

  update(data: CreateDropdown): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/${data.id}`;
    return this.http.put<RequestResponse<string>>(url, data);
  }

  getExistenceFieldName(params: Params): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/Name/Existence`;
    return this.http.get<RequestResponse<boolean>>(url, { params });
  }

  getOptionsByDropdownType(
    dropdownType: string
  ): Observable<RequestResponse<{options:Select[]}>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/${dropdownType}/Options`;
    return this.http.get<RequestResponse<{options: Select[]}>>(url);
  }
}
