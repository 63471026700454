import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Observable } from 'rxjs';

import { RequestResponse } from 'src/app/shared/interfaces';
import { ListModel } from 'src/app/shared/models';
import { APIUrls, BaseHttp } from 'src/app/shared/api';
import {
  MeasureData,
  MeasureFilterData,
  MeasureListItem,
  MeasureLookups,
  MeasureSectionLookups,
  MeasureTypesLookups,
} from 'src/app/private/interfaces';

@Injectable()
export class ConfigurationMeasureTypeService extends BaseHttp {
  path: string = 'MeasureType';

  getMeasureTypesLookups(): Observable<RequestResponse<MeasureTypesLookups>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/Lookups`;
    return this.http.get<RequestResponse<MeasureTypesLookups>>(url);
  }

  getMeasureSectionLookups(): Observable<
    RequestResponse<MeasureSectionLookups>
  > {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/Hierarchic/Lookups`;
    return this.http.get<RequestResponse<MeasureSectionLookups>>(url);
  }

  getList(
    filterData: MeasureFilterData
  ): Observable<RequestResponse<ListModel<MeasureListItem>>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/Dashboard`;
    return this.http.post<RequestResponse<ListModel<MeasureListItem>>>(
      url,
      filterData
    );
  }

  removeMeasure(id: string): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/${id}`;
    return this.http.delete<RequestResponse<boolean>>(url);
  }

  changeStatus(data: {
    id: string;
    enable: boolean;
  }): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/${data.id}/status`;
    return this.http.patch<RequestResponse<boolean>>(url, {
      enable: data.enable,
    });
  }

  create(data: MeasureData): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}`;
    return this.http.post<RequestResponse<string>>(url, data);
  }

  update(
    data: MeasureData,
    id: string
  ): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/${id}`;
    return this.http.put<RequestResponse<string>>(url, data);
  }

  getData(id: string): Observable<RequestResponse<MeasureData>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/${id}`;
    return this.http.get<RequestResponse<MeasureData>>(url);
  }

  getLookups(): Observable<RequestResponse<MeasureLookups>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/Forms/Lookups`;
    return this.http.get<RequestResponse<MeasureLookups>>(url);
  }

  checkKeyCategoryExistence(
    params: Params
  ): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/KeyCategory/Existence`;
    return this.http.get<RequestResponse<boolean>>(url, { params });
  }

  checkNameCategoryExistence(
    params: Params
  ): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/${this.path}/NameCategory/Existence`;
    return this.http.get<RequestResponse<boolean>>(url, { params });
  }

}
