import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseHttp, APIUrls } from 'src/app/shared/api';
import { ListModel } from 'src/app/shared/models';
import {
  FiltersLookups,
  LoanFilterData,
  LoanListItem,
} from 'src/app/private/interfaces';
import { RequestResponse } from 'src/app/shared/interfaces';

@Injectable()
export class HomeService extends BaseHttp {
  getList(
    filterData: LoanFilterData
  ): Observable<RequestResponse<ListModel<LoanListItem>>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/Dashboard`;
    return this.http.post<RequestResponse<ListModel<LoanListItem>>>(
      url,
      filterData
    );
  }

  getActualExportGuid(filterData: LoanFilterData): Observable<Blob> {
    const body: Partial<LoanFilterData> = {
      ...filterData,
    };
    delete body.pageNumber;
    delete body.pageSize;

    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/Dashboard/Export`;

    return this.http.post(url, body, { responseType: 'blob' });
  }

  getLookups(): Observable<RequestResponse<FiltersLookups>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/Dashboard/Lookups`;
    return this.http.get<RequestResponse<FiltersLookups>>(url);
  }

  removeLoan(id: string): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${id}`;
    return this.http.delete<RequestResponse<boolean>>(url);
  }
}
