import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import {
  GeneralInformation,
  Measure,
  CalculationDataResponse,
  CalculationDataRequest,
  GeneralInfoLookups,
  CalculationApiState,
  MeasuresApiState,
  MeasuresGetData,
  GeneralInfoApiState,
  LoanApiState,
  CompletionDataResponse,
  GeneralInfoImport,
} from '../../interfaces';
import { AttachmentsRequest, RequestResponse } from 'src/app/shared/interfaces';
import { CheckGeneralInfoModel, ImportDataModel } from 'src/app/private/models';
import { BaseHttp, APIUrls } from 'src/app/shared/api';

@Injectable()
export class LoanService extends BaseHttp {
  getLookups(): Observable<RequestResponse<GeneralInfoLookups>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/GeneralInfo/Lookups`;
    return this.http.get<RequestResponse<GeneralInfoLookups>>(url);
  }

  getGeneralInfoState(
    loanId: string
  ): Observable<RequestResponse<GeneralInfoApiState>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${loanId}/GeneralInfo/State`;
    return this.http.get<RequestResponse<GeneralInfoApiState>>(url);
  }

  checkGeneralInformation(
    data: GeneralInformation,
    isImported: boolean,
    sync: boolean
  ): Observable<RequestResponse<{ sentToEmu: boolean }>> {
    let params = new HttpParams();
    if (isImported) {
      params = params.set('isImported', isImported);
    }
    if (sync) {
      params = params.set('sync', sync);
    }

    const requestData = CheckGeneralInfoModel.fromForm(data, isImported);

    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/Check`;
    return this.http.post<RequestResponse<{ sentToEmu: boolean }>>(
      url,
      requestData,
      { params }
    );
  }

  createGeneralInformation(
    data: GeneralInformation
  ): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan`;
    return this.http.post<RequestResponse<string>>(url, data);
  }

  updateGeneralInformation(
    data: GeneralInformation,
    id: string
  ): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${id}`;
    return this.http.put<RequestResponse<string>>(url, data);
  }

  getGeneralInformation(
    id: string
  ): Observable<RequestResponse<GeneralInformation>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${id}/GeneralInfo`;
    return this.http.get<RequestResponse<GeneralInformation>>(url);
  }

  getMeasuresState(
    loanId: string
  ): Observable<RequestResponse<MeasuresApiState>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${loanId}/Measures/State`;
    return this.http.get<RequestResponse<MeasuresApiState>>(url);
  }

  getMeasuresData(
    loanId: string
  ): Observable<RequestResponse<MeasuresGetData>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${loanId}/Measures`;
    return this.http.get<RequestResponse<MeasuresGetData>>(url);
  }

  checkMeasureList(data: {
    measures: Measure<AttachmentsRequest>[];
    loanId: string;
  }): Observable<RequestResponse<{ sentToEmu: boolean }>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${data.loanId}/Measures/Check`;
    return this.http.post<RequestResponse<{ sentToEmu: boolean }>>(url, {
      measures: data.measures,
    });
  }

  updateMeasureList(
    data: { measures: Measure<AttachmentsRequest>[] },
    id: string
  ): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${id}/measures`;
    return this.http.put<RequestResponse<string>>(url, data);
  }

  getLoanApiState(id: string): Observable<RequestResponse<LoanApiState>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${id}/Completion/State`;
    return this.http.get<RequestResponse<LoanApiState>>(url);
  }

  getCalculationState(
    loanId: string
  ): Observable<RequestResponse<CalculationApiState>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${loanId}/Calculation/State`;
    return this.http.get<RequestResponse<CalculationApiState>>(url);
  }

  getCalculationData(
    id: string
  ): Observable<RequestResponse<CalculationDataResponse[]>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Calculation/Loan/${id}/Calculate`;
    return this.http.get<RequestResponse<CalculationDataResponse[]>>(url);
  }

  saveCalculationData(
    data: CalculationDataRequest[],
    id: string
  ): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Calculation/Loan/${id}`;
    return this.http.put<RequestResponse<string>>(url, data);
  }

  processCalculation(
    data: CalculationDataRequest
  ): Observable<RequestResponse<CalculationDataResponse>> {
    const url = `${this.baseUrl}/${APIUrls.Calculation}/MeasureTypes/${data.measureTypeId}`;
    return this.http.post<RequestResponse<CalculationDataResponse>>(url, data);
  }

  getCompletionData(
    loanId: string
  ): Observable<RequestResponse<CompletionDataResponse>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${loanId}/Completion`;
    return this.http.get<RequestResponse<CompletionDataResponse>>(url);
  }

  plausibilityCheck(id: string): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${id}/PlausibilityCheck`;
    return this.http.patch<RequestResponse<string>>(url, {});
  }

  completeLoan(id: string): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${id}/Complete`;
    return this.http.patch<RequestResponse<string>>(url, {});
  }

  importGeneralInfoData(
    loanApplicationNumber: string
  ): Observable<RequestResponse<GeneralInfoImport>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/Import/${loanApplicationNumber}`;
    return this.http.get<RequestResponse<GeneralInfoImport>>(url);
  }

  createImportedGeneralInfoData(
    data: GeneralInformation
  ): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/Import`;
    const requestData = ImportDataModel.fromForm(data);
    return this.http.post<RequestResponse<string>>(url, requestData);
  }

  updateImportedGeneralInfoData(
    loanId: string,
    data: GeneralInformation,
    sync: boolean
  ): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/${loanId}/Import?sync=${sync}`;
    const requestData = ImportDataModel.fromForm(data);
    return this.http.put<RequestResponse<string>>(url, requestData);
  }

  getExistenceLoan(
    loanApplicationNumber: string
  ): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Loans}/Loan/Existence?loanApplicationNumber=${loanApplicationNumber}`;
    return this.http.get<RequestResponse<boolean>>(url);
  }
}
