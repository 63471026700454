import { GeneralInformation } from '../interfaces';

export class ImportDataModel {
  loanApplicationNumber!: string | null;
  baDescription!: string | null;
  piDescription!: string | null;

  static fromForm(value: Omit<GeneralInformation, 'measures'>) {
    const data = new ImportDataModel();
    data.baDescription = value.baDescription;
    data.loanApplicationNumber = value.loanApplicationNumber;
    data.piDescription = value.piDescription;

    return data;
  }
}
