import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APIUrls, BaseHttp } from 'src/app/shared/api';
import { RequestResponse } from 'src/app/shared/interfaces';
import { ReportsData } from 'src/app/private/interfaces';
import { ReportName } from 'src/app/private/enums';

@Injectable()
export class ReportsService extends BaseHttp {
  getData(): Observable<RequestResponse<ReportsData>> {
    const url = `${this.baseUrl}/${APIUrls.PBI}/Reports/${ReportName.Loan}`;
    return this.http.get<RequestResponse<ReportsData>>(url);
  }
}
