import { GeneralInformation } from '../interfaces';

export class CheckGeneralInfoModel {
  loanApplicationNumber!: string | null;
  totalAmount!: number | null;
  currency!: number | null;
  loanStatus!: number | null;

  static fromForm(
    value: Omit<GeneralInformation, 'measures'>,
    isImported: boolean
  ) {
    const data = new CheckGeneralInfoModel();
    data.totalAmount = isImported ? null : value.totalAmount;
    data.loanApplicationNumber = value.loanApplicationNumber;
    data.currency = isImported ? null : value.currency;
    data.loanStatus = value.loanStatus;

    return data;
  }
}
