import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseHttp, APIUrls } from 'src/app/shared/api';
import {
  CalculationModuleData,
  CalculationModuleDataSection,
  CalculationModuleFilterData,
  CalculationModuleListItem,
  CalculationModuleLookups,
} from 'src/app/private/interfaces';
import { RequestResponse } from 'src/app/shared/interfaces';
import { ListModel } from 'src/app/shared/models';
import { Params } from '@angular/router';

@Injectable()
export class ConfigurationCalculationModuleService extends BaseHttp {
  getList(
    filterData: CalculationModuleFilterData
  ): Observable<RequestResponse<ListModel<CalculationModuleListItem>>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/Form/Dashboard`;
    return this.http.post<
      RequestResponse<ListModel<CalculationModuleListItem>>
    >(url, filterData);
  }

  removeModule(id: string): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/Form/${id}`;
    return this.http.delete<RequestResponse<boolean>>(url);
  }

  getLookups(): Observable<RequestResponse<CalculationModuleLookups>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/Form/Lookups`;
    return this.http.get<RequestResponse<CalculationModuleLookups>>(url);
  }

  checkNameCalculationExistence(
    params: Params
  ): Observable<RequestResponse<boolean>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/Form/Existence`;
    return this.http.get<RequestResponse<boolean>>(url, { params });
  }

  getCalculationData(
    id: string
  ): Observable<RequestResponse<CalculationModuleData>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/Form/${id}`;
    return this.http.get<RequestResponse<CalculationModuleData>>(url);
  }

  createCalculation(name: string): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/Form`;
    return this.http.post<RequestResponse<string>>(url, { name });
  }

  updateCalculation(data: {
    name: string;
    id: string;
  }): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/Form/${data.id}`;
    return this.http.put<RequestResponse<string>>(url, { name: data.name });
  }

  publishCalculation(id: string): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/Form/${id}/Publish`;
    return this.http.patch<RequestResponse<string>>(url, {});
  }

  updateSection(
    data: CalculationModuleDataSection
  ): Observable<RequestResponse<string>> {
    const url = `${this.baseUrl}/${APIUrls.Configuration}/Section/${data.id}`;
    return this.http.put<RequestResponse<string>>(url, data);
  }
}
